<template>
  <div class="div-nav2">
    <div class="div-top">
        <div class="div-left">
            <img src="../../assets/homepage/卓翔集团公众号二维码.png" alt="" srcset="">
            <p>官方微信公众号</p>
        </div>
        <div class="div-itme">
            <div class="itmetext">
                卓期商务服务(深圳)有限公司 版权所有  
                <a href="https://beian.miit.gov.cn/" style="color: #a3a3a3;font-size: 12px;" target="_blank" rel="nofollow">
                    【粤ICP备2024314106号-1】
                </a>
            </div>
            <div class="itmetext">
                电话:&nbsp;&nbsp;0755-21006061 邮箱: 2355700272@qq.com
            </div>
            <div class="itmetext">
                地址:&nbsp;&nbsp; 深圳市龙华区民治街道新牛路港深国际中心3楼363室
            </div>
        </div>
        <div class="div-right">
            <p class="right-top">
                <b>0755-21006061</b>
            </p>
            <p>只需一个电话，深圳注册公司选择卓翔，将给您提供最优质的服务</p>
        </div>
    </div>
    <div class="line"></div>
    <div class="div-bom">
        <p>友情链接</p>
        <div class="bomtext">
            <span>防伪码查询</span>
            <span>成都营销策划公司</span>
            <span>代理记账注册广州公司</span>
            <span>重庆质量认证</span>
            <span>会计公司</span>
            <span>宣传片</span> 
            <span>苏州注册公司</span>
            <span>资质办理</span>
            <span>公司转让网址</span>
            <span>上海注册公司</span>
            <span>审计事务所</span>
            <span>上海注册公司商标买卖</span>
            <span>西安网站建设</span>
            <span>seo优化</span>
            <span>财税软件</span>
        </div>
    </div>
  </div>
</template>
<style scoped>
/* @import url("./bottomnav.css"); */
.div-nav2 {
    /* width: 1920px; */
    width: 100%;
    height: 385px;
    background: #091627;
    
}
.div-nav2 .div-top{
    max-width: 1200px;
    height: 180px;
    /* border:1px red solid; */
    margin: 0 auto;
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}
.div-nav2 .div-top .div-left{
    max-width: 126px;
    height: 100%;
    /* border: 1px green solid; */
    color: #ccc;
    text-align: center;
}
.div-nav2 .div-top .div-left img{
    display: block;
    width: 126px;
    height: 126px;
}
.div-nav2 .div-top .div-itme{
    max-width: 600px;
    width: 45%;
    height: 100%;
    /* border: 1px green solid; */
    color: #ccc;
    text-align: left;
    padding-top: 30px;
}
.div-nav2 .div-top .div-itme .itmetext{
    width: 100%;
    height: 30px;
    line-height: 30px;
    padding-top: 4px;
}
.div-nav2 .div-top .div-itme .itmetext:hover{
    cursor: pointer;
    /* color: red; */
}
.div-nav2 .div-top .div-right{
    width: 400px;
    height: 100%;
    /* border: 1px green solid; */
    color: #ccc;
    text-align: left;
    font-size: 12px;
}
.div-nav2 .div-top .div-right .right-top{
    width: 100%;
    font-size: 48px;
    font-weight: 800;
    padding-top: 10px;
    margin: 0px;
}

.div-nav2 .line{
    max-width: 1200px;
    height: 1px;
    margin: 0 auto;
    background-color: #ccc;
    margin-top: 20px;
}
.div-nav2 .div-bom{
    max-width: 1200px;
    height: 80px;
    /* border: 1px red solid; */
    margin: 0 auto;
    margin-top: 20px;
    text-align: left;
    font-size: 12px;
    color: #ccc;
}
.div-nav2 .div-bom p{
    margin: 0px;
}
.div-nav2 .div-bom .bomtext{
    height: 20px;
    line-height: 20px;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}
.div-nav2 .div-bom .bomtext span{
    display: inline-block;
    height: 20px;
}
.div-nav2 .div-bom .bomtext span:hover{
    cursor: pointer;
    color: red;
    text-decoration: underline;
}
.div-nav2 .div-bom .bomtext span::after{
    content: "|";
    margin: 0px 2px;
}
.div-nav2 .div-bom .bomtext span:nth-last-child(1)::after{
    content: "";
    margin: 0px 2px;
}

</style>
<script>
export default {
  name: "Bottom",
};
</script>